<template>
    <div class="o365-data-grid o365-root row-container bg-body" ref="containerRef" :id="gridControl.id">


        <div class="o365-column-definitions d-none">
            <slot name="systemcolumn">
                <BaseColumn colId="o365_System" class="text-center" pinned="left" suppressMovable suppressSelection
                    disableResizable disableMenu width="30" maxWidth="30" suppressNavigable
                    style="user-select: 'none';">
                    <template #default="{ row }">
                        <i v-if="row.error" class="text-danger bi bi-exclamation-triangle-fill" :title="row.error"></i>
                        <div v-else-if="row.isSaving" class="spinner-border spinner-border-sm mt-1" role="status"></div>
                        <i v-else-if="row.isNewRecord" class="bi bi-star-fill" @click.stop="() => { }"></i>
                        <i v-else-if="row.hasChanges" class="bi bi-pencil-fill" style="cursor: pointer;" :title="$t('Save')"
                            @click.stop="() => { }"></i>
                        <i v-else-if="row.current" class="bi bi-caret-right-fill"></i>
                    </template>
                    <template #headertext></template>
                    <template #filter>
                        <div class="w-100 text-center"><i class="bi bi-search"></i></div>
                    </template>
                </BaseColumn>
            </slot>
            <slot></slot>
            <slot name="actioncolumn"></slot>
        </div>

        <slot name="cardheader"></slot>

        <BodyWrapper :disabled="hideGridMenu">
            <BodyWrapper :disabled="hideGridMenu">
                <div class="o365-grid-body" ref="gridBodyRef" :class="{
                    'center-viewport-overflown': gridControl.showWidthScrollbar,
                    'grid-scroll-at-start': gridControl.widthScrollState === 'start',
                    'grid-scroll-at-end': gridControl.widthScrollState === 'end',
                    'in-edit-mode': gridControl.gridFocusControl?.editMode
                }">

                    <!-- HEADER COMP -->
                    <slot v-if="!noHeader" name="header">
                        <BaseHeader />
                    </slot>

                    <!-- DATA LIST COPM -->
                    <slot name="datalist">
                        <BaseDataList :data="data" :rowHeight="rowHeight" setViewportContainer :dataLength="dataLength">
                            <template #left="{row}">
                                <div class="o365-body-row" 
                                    :class="[
                                        { 'active': row.item.current && !noActiveRows, 'selected': row.item.isSelected },
                                        computedRowClass(row.item, 'left'),
                                        { 'd-none': row.isLoading }
                                    ]" 
                                    :style="[
                                        { 'height': rowHeight + 'px' },
                                        { 'transform': 'translateY(' + row.pos + 'px)' },
                                        computedRowStyle(row.item, 'left')
                                    ]" 
                                    :data-o365-rowindex="row.index">
                                    <template v-for="col in gridControl.columns.leftColumns" :key="col.colId">
                                        <template v-if="!col.hide && col.pinned === 'left'">
                                            <BaseCell :col="col" :row="row"
                                                :selectionClass="gridControl.navigation?.getSelectionClass(col.order, row.index)"
                                                :isActiveEditCell="activeEditCell === col.order + '-' + row.index" />
                                        </template>
                                    </template>
                                </div>
                            </template>

                            <template #center="{row}">
                                <div class="o365-body-row" 
                                    :class="[
                                        { 'active': row.item.current && !noActiveRows, 'selected': row.item.isSelected },
                                        computedRowClass(row.item),
                                        { 'd-none': row.isLoading }
                                    ]" 
                                    :style="[
                                        { 'height': rowHeight + 'px' },
                                        { 'transform': 'translateY(' + row.pos + 'px)' },
                                        computedRowStyle(row.item)
                                    ]" 
                                    :data-o365-rowindex="row.index">
                                    <template v-for="col in gridControl.columns.centerColumns" :key="col.colId">
                                        <template v-if="!col.hide && col.pinned == null">
                                            <BaseCell :col="col" :row="row"
                                                :selectionClass="gridControl.navigation?.getSelectionClass(col.order, row.index)"
                                                :isActiveEditCell="activeEditCell === col.order + '-' + row.index" />
                                        </template>
                                    </template>
                                </div>
                            </template>

                            <template #right="{row}">
                                <div class="o365-body-row" 
                                    :class="[
                                        { 'active': row.item.current && !noActiveRows, 'selected': row.item.isSelected },
                                        computedRowClass(row.item, 'right'),
                                        { 'd-none': row.isLoading }
                                    ]" 
                                    :style="[
                                        { 'height': rowHeight + 'px' },
                                        { 'transform': 'translateY(' + row.pos + 'px)' },
                                        computedRowStyle(row.item, 'right')
                                    ]" 
                                    :data-o365-rowindex="row.index">
                                    <template v-for="col in gridControl.columns.rightColumns" :key="col.colId">
                                        <template v-if="!col.hide && col.pinned === 'right'">
                                            <BaseCell :col="col" :row="row"
                                                :selectionClass="gridControl.navigation?.getSelectionClass(col.order, row.index)"
                                                :isActiveEditCell="activeEditCell === col.order + '-' + row.index" />
                                        </template>
                                    </template>
                                </div>
                            </template>

                            <template #misc>
                                    <BaseCellEditor v-if="gridControl.gridFocusControl?.editMode" ref="cellEditorRef"
                                        :activeCell="gridControl?.gridFocusControl?.activeCell" :data="data"
                                        :gridContainer="containerRef" />

                                <OGridContextMenu v-if="!disableNavigation" ref="contextMenuRef">
                                    <template #top="scope">
                                    <!-- @slot
                                    @description Top part of the context menu -->
                                    <slot name="contextmenuTop" :column="scope.column" :row="scope.row" :close="scope.close"></slot>
                                    </template>
                                    <template #bottom="scope">
                                    <!-- @slot
                                    @description Bottom part of the context menu -->
                                    <slot name="contextmenuBottom" :column="scope.column" :row="scope.row" :close="scope.close"></slot>
                                    </template>
                                </OGridContextMenu>
                            </template>

                            <template #overlay>
                                <slot name="overlay"></slot>
                            </template>
                        </BaseDataList>
                    </slot>

                    <!-- FOOTER COMP -->
                    <slot name="footer">

                    </slot>

                    <!-- OPTIONAL BOTTOM SLOT (loading indicatos for example) -->
                    <slot name="bodybottom">

                    </slot>

                    <div v-show="gridControl.showWidthScrollbar" class="o365-body-horizontal-scroll"
                        style="height: 17px; max-height: 17px; min-height: 17px; width: 100%;">
                        <div class="o365-body-horizontal-scroll-left-spacer"
                            :style="{ 'min-width': gridControl.columns.leftPinnedWidth + 'px' }"></div>
                        <div class="o365-body-horizontal-scroll-viewport" :style="{ 'width': viewPortWidth + 'px' }">
                            <div class="o365-body-horizontal-scroll-container"
                                style="height: 17px; max-height: 17px; min-height: 17px;"
                                :style="[{ 'width': gridControl.columns.centerWidth + 'px', 'left': gridControl.columns.leftPinnedWidth + 'px' }]">
                            </div>
                        </div>
                        <div class="o365-body-horizontal-scroll-left-spacer"
                            :style="{ 'min-width': gridControl.columns.rightPinnedWidth + 'px' }"></div>
                    </div>

                </div>

                <!-- VERTICAL TABS INSIDE GRID BODY -->
                <slot name="gridbodymenutabs">

                </slot>
            </BodyWrapper>

            <!-- SLOT FOR GRID SIDE MENU -->
            <slot name="gridsidemenu">

            </slot>

        </BodyWrapper>

        <!-- STATUS BAR -->
        <div v-if="!noStatusBar" class="o365-footer py-1 p-1 d-flex">
            <slot name="statusbar">
                <BaseInfoItems />
            </slot>
        </div>

    </div>
</template>

<script lang="ts">
import type { Ref, ComputedRef } from 'vue';

export interface IBaseGridProps {
    /** Array of items for the data list */
    data: any[];
    /** Colum definitions to be used instead of the default slot */
    columns?: any[];
    /** String, dynamic class object or function that will be bound to the row class property. The current row is provided to the function as an argument */
    rowClass?: any;
    /** String, dynamic class object or function that will be bound to the row style property. The current row is provided to the function as an argument */
    rowStyle?: any | ((item: any, pinned: 'left' | 'right') => any);
    /** Row height in pixels */
    rowHeight?: number;

    // MENU DEPENDANT PROPS
    /**
    * The url to be used in the details iframe tab
    * @example `${site.oldGenUrl}/workflow-item?ID=${dsItems.current.ID}&HideNav=true`
    */
    detailIframe?: string;
    /** The label used on the detail iframe tab */
    detailTabTitle?: string;
    /** The initial width on the sidepanel menu */
    initialMenulWidth?: string;
    /**
    * An array of custom tab definitions for the grid sidemenu details tab
    * @example [
    *   { title: 'Custom Tab', id: 'tab1', iconClass: 'bi bi-1-square-fill', component: MyTabComponent}
    * ]
    */
    menuTabs?: any[];

    // PLUGIN DISABLES/ENABLES
    /** When set to true will not render header */
    noHeader?: boolean;
    /** When set to true will not render the status bar */
    noStatusBar?: boolean;
    /** When set to `true` will not render the grid sidepanel menu */
    hideGridMenu?: boolean;
    /** When set to `true` the grid setup menu will be initially collapsed */
    collapseGridMenu?: boolean;
    // COMPOSABLE PLUGINS
    /** When set to `true` will disable grid navigation features */
    disableNavigation?: boolean,
    /** When set to `true` will disable context menu (also disabled by default when navigation is disabled) */
    disableContextMenu?: boolean,
    /** When set to true will disable the new record button and new records container */
    disableBatchRecords?: boolean;
    /** Will make all columns unpinned and disable any pinning functionality */
    disablePinning?: boolean;
    /** Will disable sorting */
    disableSorting?: boolean;
    /** Will disable column reordering */
    disableColumnMove?: boolean;
    /** Will disable virtual scroll composable */
    disableVirtualScroll?: boolean;
    /** Initial number of items to render for visual scroll. */
    itemsToRender?: number;

    /** When set to true will not render multi-select column */
    hideMultiselectColumn?: boolean;
    /** When set to true will not render action column */
    hideActionColumn?: boolean;
    /** When set to true will not render system column (current row indicator) */
    hideSystemColumn?: boolean;
    /** When set to false will not stylize active (current) row */
    noActiveRows?: boolean;

    /** 
    * Pre-created grid control consume by the base grid. 
    * Used when there's need to add additional functionalities like DataGrid for example.
    */
    gridControl?: BaseGridControl;

    /** 
    * Composable function that takes in source data ref with grid control and returns computed data array to be shown in grid.  
    * When provided will overide the default array implementation.  
    *
    * Is asynchronous to allow dynamic dependencies loading.
    */
    dataTransformComposable?:  (dataRef: Ref<any[]>, gridControl: Ref<BaseGridControl>) => Promise<ComputedRef<any[]>>;

    /**
    * Override the row click handler, when provided will not set current index
    * @param {DataItem} row - the row that was clicked on
    * @param {MouseEvent} e - the click event 
    * @example (row, e) => dsTest.setCurrentIndex(row.index)
    */
    rowclickhandler?: (row: any, e: MouseEvent) => void;

    // Filter stuff
    /** Disable filter row rendering */
    disableFilterRow?: boolean;
    // Header stuff
    /** @ignore */
    useLeftColumnSizers?: boolean;

    /** Override data length for the base list */
    dataLength?: number

    // DATA GRID STUFF
    /** Select list will contain only visible grid columns and sort order columns set on data object */
    //onDemandFields?: boolean;
    /** When set to true the grid will load the dataobject after mount */
    //loadDataObject?: boolean;
    /** Use delete confirm for delete actions. Is true by default */
    //disableDeleteConfirm?: boolean;
    /** When set to false will not render filter row */
    /** Use group by folders */
    //groupByFolders?: boolean;
    /** An array of initial field filters. For example `['Title', {name:'StatusCode', distinct:'StatusCode'}]` */
    //fieldFilters?: any[];
    /** Enables grouping in the grid, can be passed as options object or as boolean for default configuration */
    //groupBy?: boolean|any,
    /** The column definition used when grouping is enabled for the Group column */
    //groupColumnDefinition?: any;
    /** The column definition used to render TreeColumn when treeify is enabled on the provided dataobject */
    //treeColumnDefinition?: any;
    /**
    * Enables dynamic loading for the grid. When set to false will set the inner height to loaded data length.
    * When using Tree or GroupBy default is 'false' otherwise will be 'true'
    */
    // dynamicLoading: { default: (rawProps) => !(rawProps.treeColumnDefinition || rawProps.groupBy) }

    /**
    * GroupBy options
    * @ignore
    */
    //groupByOptions?: any;
}
</script>

<script setup lang="ts">
/**
* Base grid component
* @definition
*/

// Controls
import { default as BaseGridControl } from 'o365.controls.Grid.BaseGrid.ts';

// Components
import BodyWrapper from 'o365.vue.components.Grid.BodyWrapper.vue';
import Overlay from 'o365.vue.components.Overlay.vue';
import BaseColumn from 'o365.vue.components.Grid.BaseColumn.vue';

// System
import { injectionKey } from 'o365.vue.composables.Grid.BaseControlInject.ts';
import { ref, useAttrs, provide, computed, defineAsyncComponent, onMounted, onBeforeUnmount, watch, toRef, Suspense } from 'vue';

// Async components
const BaseDataList = defineAsyncComponent(() => import('o365.vue.components.Grid.BaseList.vue'));
const BaseCell = defineAsyncComponent(() => import('o365.vue.components.Grid.BaseCell.vue'));
const BaseCellEditor = defineAsyncComponent(() => import('o365.vue.components.Grid.BaseCellEditor.vue'));
const BaseHeader = defineAsyncComponent(() => import('o365.vue.components.Grid.BaseHeader.vue'));
const BaseInfoItems = defineAsyncComponent(() => import('o365.vue.components.Grid.BaseInfoItems.vue'));

const OGridContextMenu = defineAsyncComponent(() => import('o365.vue.components.DataGrid.ContextMenu.vue'));

//const BaseColumn = defineAsyncComponent(() => import('o365.vue.components.Grid.BaseColumn.vue'));
const attrs = useAttrs();
const props = withDefaults(defineProps<IBaseGridProps>(), {
    rowHeight: 34,
    // @ts-ignore (ignore error, this will compile to default: raw => raw.disableNavigation)
    disableContextMenu: raw => raw.disableNavigation 
});

/** Main container element ref */
const containerRef = ref<HTMLElement>(null);
/** Grid body container element ref */
const gridBodyRef = ref<HTMLElement>(null);
/** Grid cell editor component ref */
const cellEditorRef = ref(null);

/** Promise for awaiting full editor mount */
let cellEditorMountedPromise = null;
/** Resolve function for editor mount promise */
let cellEditorMountedPromiseResolve = null;

/** CellEditor Suspense resolve handler */
function onEditorMounted() {
    if (cellEditorMountedPromiseResolve) {
        cellEditorMountedPromiseResolve();
    } 
}

/** Function for getting resolved cell editor ref */
async function getCellEditorRef() {
    if (cellEditorRef.value) {
        return cellEditorRef;
    } else if (gridControl.value.gridFocusControl?.editMode) {
        // Await editor component resolve
        if (!cellEditorMountedPromise) {
            cellEditorMountedPromise = new Promise(res => {
                cellEditorMountedPromiseResolve = res;
            });
        }
        await cellEditorMountedPromise;
        cellEditorMountedPromiseResolve = null;
        cellEditorMountedPromise = null;

        try {
            const index = cellEditorRef.value.colIndex
            gridControl.value.columns.columns[index-1]?.cellEditor?.__asyncLoader?.call();
            gridControl.value.columns.columns[index+1]?.cellEditor?.__asyncLoader?.call();
        } catch (ex) {
            console.error(ex);
        }

        return cellEditorRef;
    } else {
        return cellEditorRef;
    }
}

const gridControl = props.gridControl
    ? toRef(props, 'gridControl')
    : ref<BaseGridControl>(new BaseGridControl({
        id: attrs.id,
        props: props
    }));

if (props.gridControl) {
    gridControl.value.props = props;
}

const contextMenuRef = ref(null);
gridControl.value.refs.contextMenu = contextMenuRef;

const viewportRef = computed(() => {
    return gridControl.value.viewport;
});

// defineExpose macro must be before any top-level await statement for it work as intended
defineExpose({ gridControl });


if (!props.disableNavigation) {
    const { default: useDataGridNavigation } = await import('o365.vue.composables.Grid.BaseNavigation.ts');
    const NavigationExtension = await gridControl.value.getExtension('navigation');
    useDataGridNavigation({
        containerRef: viewportRef,
        gridControl: gridControl,
        rowSelector: '.o365-body-row',
        cellSelector: '.o365-body-cell',
        //widthScrollerRef: widthScrollerRef,
        getCellEditorRef: getCellEditorRef,
        enableContextMenu: !props.disableContextMenu,
        enableDrag: true
    }, NavigationExtension);
}


/** Resolved row class */
const computedRowClass = computed(() => {
    switch (typeof props.rowClass) {
        case 'function':
            return props.rowClass;
        case 'object':
        case 'string':
            return () => props.rowClass;
        default:
            return () => '';
    }
});

/** Resolved row style */
const computedRowStyle = computed(() => {
    switch (typeof props.rowStyle) {
        case 'function':
            return props.rowStyle;
        case 'object':
        case 'string':
            return () => props.rowStyle;
        default:
            return () => '';
    }
});

const activeEditCell = computed(() => {
    return gridControl.value.gridFocusControl?.editMode ? gridControl.value.gridFocusControl?.activeCell : null;
});

// TODO: Move to grid control
const viewPortWidth = computed(() => {
    gridControl.value.setViewPortWidth()
    return gridControl.value.scrollerWidth;
});

// Provide the grid control for all sub components to hook into
provide(injectionKey, gridControl);

onMounted(() => {
    gridControl.value.initialize({
        container: containerRef.value,
        watchFn: watch,
    });

    const hasEditable = gridControl.value.columns.columns.some(col => col.editable);
    if (hasEditable) {
        // If grid has an editable cell preload the editor component
        BaseCellEditor.__asyncLoader();
    }
});

onBeforeUnmount(() => {
    gridControl.value.destroy();
});

</script>